<template>
    <div v-cloak>
        <transition name="fade" appear appear-active-class="fade-enter-active">
            <div class="wrap">

                <router-link class="goBack" to="/">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z"
                              fill="#0033A0"/>
                    </svg>
                    назад
                </router-link>

                <h1>Календарь посещений<br>Активные задачи</h1>

                <div class="front-page">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Номер</th>
                            <th>Дата
                                <v-icon @click="sortApplications('localDateTime')">
                                    mdi-swap-vertical
                                </v-icon>
                            </th>
                            <th>ФИО
                                <v-icon @click="sortApplications('lastName')">
                                    mdi-swap-vertical
                                </v-icon>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(application, applicationIndex) in applications"
                            :class="(application.localDateTime < new Date() || application.assessmentId != null) ? 'tr-inactive' : ''"
                            :key="applicationIndex"
                            @click="application.assessmentId == null ? $router.push('/task1/'+application.id) : $router.push('/assessment/'+application.assessmentId)"
                        >
                            <td>{{applicationIndex + 1}}</td>
                            <td>{{application.localDateTime | dateFormat}}</td>
                            <td>{{application.lastName}} {{application.firstName}} {{application.middleName}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <v-btn rounded
                           v-if="this.page+1 < this.totalPages"
                           @click="loadMore"
                    >
                        Загрузить ещё
                    </v-btn>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    import api from "@/modules/api";

    export default {
        name: 'Calendar',
        data() {
            return {
                applications: [],
                sort: 'localDateTime',
                initialSort: 'localDateTime',
                direction: 'desc',
                page: 0,
                initialPage: 0,
                amount: 50,
                totalPages: 0,
            }
        },
      filters: {
        dateFormat: function (date) {
          if (date == null) {
            return ''
          }
          const mdate = new Date(date)
          const options = {
            //timeZone: 'Europe/Moscow',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }
          return mdate.toLocaleDateString('ru-RU', options)
        }
      },
        methods: {
            async getApplications() {
                console.log('getApplications')
                const url = '/applications?page=' + this.page + '&size=' + this.amount + '&sort=' + this.sort + ',' + this.direction
                const req = await api.get(url)
                if (req?.ok) {
                    const pages = req?.payload?.page?.totalPages
                    if (pages) {
                        this.totalPages = pages
                    }
                    const applications = req?.payload?._embedded?.applications
                    if (applications?.length) {
                        applications.forEach(function (application) {
                            const date = new Date(application.localDateTime)
                            application.localDateTime = date
                        })
                    }
                    return applications
                }
                alert('Ошибка при обращении к серверу!')
            },
            async loadApplications(reset = false) {
                const applications = await this.getApplications()
                if(reset) {
                    this.applications = applications
                    return
                }
                this.applications = [...this.applications, ...applications]
            },
            async loadMore() {
                this.page++
                await this.loadApplications()
            },
            async sortApplications(sort) {
                this.page = this.initialPage
                this.sort = sort
                this.direction = this.direction === 'asc' ? 'desc' : 'asc'
                await this.loadApplications(true)
            }
        },
        async beforeMount() {
            await this.loadApplications()
        },
    }
</script>

<style lang="scss">
    @import "../styles/main.scss";
</style>


<style scoped>
.tr-inactive td {
  color: gray;
}
</style>
